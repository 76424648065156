// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-event-post-js": () => import("./../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artiklar-js": () => import("./../src/pages/artiklar.js" /* webpackChunkName: "component---src-pages-artiklar-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-kommande-js": () => import("./../src/pages/events/kommande.js" /* webpackChunkName: "component---src-pages-events-kommande-js" */),
  "component---src-pages-events-tidigare-js": () => import("./../src/pages/events/tidigare.js" /* webpackChunkName: "component---src-pages-events-tidigare-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-js": () => import("./../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-sponsorer-js": () => import("./../src/pages/sponsorer.js" /* webpackChunkName: "component---src-pages-sponsorer-js" */),
  "component---src-pages-tack-js": () => import("./../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */)
}

